import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import Route from "react-router-dom/Route";
import { BrowserRouter, Switch } from "react-router-dom";

import { ScrollContext } from "react-router-scroll-4";
import "./index.scss";

//test commit again with the team

// ** Import custom components **
import store from "./store";
import App from "./components/app";

import Login from "./components/login/Login";


class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
           
            <Switch>
              <App>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={Login}
                />
          
              </App>
            </Switch>
       
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
