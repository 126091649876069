/* eslint-disable no-unused-vars */
const axios = require('axios');
const axiosInstance = axios.create({
  // baseURL: `https://dev.msilvendortrucknav.com/inbound/`
   baseURL: 'https://projects.autometrics.in/enmovil/ensologins/'
  // baseURL: 'http://34.131.131.70:5002/enmovil/ensologins/'
  /* other custom settings */
});

module.exports = axiosInstance;
