import React, {Component} from "react";
// import ReactDom,{ render } from 'react-dom';
// import axios from 'axios';
// import { Redirect } from 'react-router';
import redirectURL from '../redirectURL'
// import LoginForm from './Loginform';
import Select from 'react-select';
// import Lottie from 'react-lottie';
import loginAnimation from '../../assets/animations/login_animation.json'
import enmovilSmallLogo from '../../assets/images/enmovil_small_image.png'
// import checkFile from '../../assets/icons/clock.png'
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msalAuthConfig';
//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

// var images = require.context('../../assets/images/', true);

class Login extends Component {
    constructor(props) {
        console.log("Login Page constructor");
        super(props);
        this.msalInstance = new PublicClientApplication(msalConfig);
        // var refur='';
        
        this.state = {
            style: {},
            projects:[],
            environments:[],
            environment_type: '',
            overallData: [], 
            project_name:'',
            is_envselected : false,
            validationMessage : "",
            defaultOptions:{
                loop:true,
                autoplay:true,
                animationData:loginAnimation,
                renderSettings:{
                    preserveAspectRatio: 'xMidYMid slice'
                }
            }
        };
    }

    componentDidMount() {
        redirectURL.post("/getprojectsList").then((response) => {
            const data = response.data;
            data.forEach(group => {
                group.projects.sort((a, b) => a.project_name.localeCompare(b.project_name));
            });
            const orderedEnvTypes = ['PROD', 'POC'];
            data.sort((a, b) => {
                const indexA = orderedEnvTypes.indexOf(a.env_type);
                const indexB = orderedEnvTypes.indexOf(b.env_type);
                return indexA - indexB;
            });
            const sortedProjects = data.flatMap(group =>
                group.projects.map(project => ({
                    value: project.project_name,
                    label: project.project_name
                }))
            );
            this.setState({ projects: sortedProjects });
        })
            .catch(error => console.error('Error fetching projects:', error));
    }

    onClickEnmovilAdLogin = (event) => {
        event.preventDefault()
        console.log(this.msalInstance,"45")
        if (!this.state.project_name) {
            this.setState({
                validationMessage: "Fields marked with * are required!"
            });
        } else {
        this.msalInstance.loginPopup()
        .then(response => {
            this.setState({
                loadshow: 'show-m',
                overly: 'show-m',
                validationMessage: ""
            });
            console.log(response,"53")
            localStorage.setItem("sso_data",response)
            var params = {
                "project_name":this.state.project_name.value,
                "environment_type":this.state.environment_type.value,
                "username":response.account.name,
                "email":response.account.username
            }
            console.log(params,"57")
            redirectURL.post("/getCustomerToken",params).then((response1)=>{
                console.log(response1.data,"59")
                // if(response1.data.length >0){
                    console.log(`${response1.data.app_url}enmuserlogin/${response1.data.token}`,"60")
                    // window.open(`${response1.data.app_url}enmuserlogin/${response1.data.token}`,'_blankx')
                    if (response1.data.is_authentication_required == 0) {
                        this.openInNewTab(response1.data.app_url)
                        this.setState({
                            main_link: response1.data.app_url
                        })
                    }
                    else {
                        this.openInNewTab(`${response1.data.app_url}enmuserlogin/${response1.data.token}`)
                        this.setState({
                            main_link: `${response1.data.app_url}enmuserlogin/${response1.data.token}`
                        })
                    }
                    
                    
                // }else{
                //     this.setState({
                //         main_link:"",
                //         project_name:"",
                //         environment_type:""
                //     })
                //     alert("Authentication Failed")
                // }
                
            })  
            
            // localStorage.setItem("enmovil-ad-token", JSON.stringify(response))
            // var email = "nanda@enmovil.in"
            // var is_enmovil_transporter = 0

            // var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            // var usrpwd = ppwwdd("maruti123!")
            // //console.log("ppwwdd ", usrpwd)

            // var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            // var usremail = ppwwdd(email)
            // const fdata = {
            //     em:usremail,
            //     a:usrpwd,
            //     is_enmovil_transporter: is_enmovil_transporter
            // }
            // this.forwardLoginAuthentication(fdata)
        })
        .catch(error => {
            console.error('Login error:', error);
            this.setState({
                show: true,
                basicTitle: 'Authentication Failed, Please contact Admin',
                basicType: "danger",
            });
        });
        }
        
    }

    // handleInputChange = (e) => {
    //     const environment_type = e
    //     this.setState({ environment_type, project_name: "",is_envselected : true,validationMessage : ""}, this.filterProjects);
    // }


    // filterProjects = () => {
    //     const { environment_type, overallData } = this.state;
    //     const filteredProjects = [];

    //     overallData.forEach((item) => {
    //         if (item.env_type === environment_type.value) {
    //             item.projects.forEach((project) => {
    //                 filteredProjects.push({ value: project.project_name, label: project.project_name });
    //             });
    //         }
    //     });

    //     this.setState({ projects: filteredProjects });
    // }

    openInNewTab(url) {
        // Create a new anchor element
        const anchor = document.createElement('a');
      
        // Set the href to the URL you want to open
        anchor.href = url;
      
        // Set target to '_blank' to open in a new tab or window
        anchor.target = '_blank';
      
        // Append the anchor to the body (required for some browsers)
        document.body.appendChild(anchor);
      
        // Simulate a click on the anchor
        anchor.click();
      
        // Remove the anchor from the body after click
        document.body.removeChild(anchor);
      }
   
    render() {
        // console.log(localStorage.getItem("test_token"), "test_token")
        return (
            <div className="main_login">
                <div className="background_wallpaper">
                    
                </div>
                {/* <div className="glass_layer"> */}
                    <div className="card">
                        <img src={enmovilSmallLogo} alt="enmovil_logo" className="enmovil_small_logo"/>
                        {/* <h4 className="main-title_form">SSO Login</h4>
                        <p className="subtitle_form">Register Here!</p> */}
                        {/* <Lottie className="animation_form" options={this.state.defaultOptions} style={{width:"65%",height:"30%"}}/> */}
                        <form method="POST" id="registration_form" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickEnmovilAdLogin}>
                                {this.state.validationMessage && (
                                    <div style={{ color: 'red', marginBottom: '10px' }}>
                                        {this.state.validationMessage}
                                    </div>
                                )}
                                {/* <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12" style={{fontWeight:"bold"}}>Environment *</label>
                                    <Select
                                        value={this.state.environment_type}
                                        placeholder={"Select Environment Type"}
                                        closeMenuOnSelect={true}
                                        onChange={this.handleInputChange}
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        // style={{borderRadius:"0px"}}
                                        options={this.state.environments}
                                        required />
                                </div> */}
                                {/* {this.state.is_envselected ? */}
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12" style={{fontWeight:"bold"}}>Project *</label>
                                    <Select
                                        value={this.state.project_name}
                                        placeholder={"Select Project Name"}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => this.setState({
                                            project_name :e,
                                            validationMessage : ""
                                        })}
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        // style={{borderRadius:"0px"}}
                                        options={this.state.projects}
                                        required />
                                </div>
                                {/* : <></>}            */}
                                <div className="col button_switch">
                                    <button type="submit" className="btn submit_btn">Login With M365</button>
							    </div>
                        </form>
                    </div>
                {/* </div> */}
            </div>

        )
    }
}


export default Login;
