import React, { Component } from 'react';
import '../assets/css/encss.css';
import { combineReducers, createStore } from 'redux';
import { sessionService, sessionReducer } from 'redux-react-session';

const reducer = combineReducers({
    session: sessionReducer
});

const store = createStore(reducer);
// Init the session service
sessionService.initSessionService(store, { driver: 'COOKIES' });


class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            sidebar:true,
            navMenus:true,
            ssinner:'show-n',
            sideimg:require('../assets/icons/sidenav-blue.png'),
            imgbg:'whgb',
            l20n:'',
            showOutIcon:'show-m',
            sclose:'show-m',
            logginStatus: true,
            userroles:[],
        }

    }

    componentDidMount() {
    }

    render(){
        return (

            <div className="page-wrapper">
                <div className="page-body-wrapper" style={{overflow:"hidden"}} id="plivebody">
                        {this.props.children}
                </div>
            </div>

        );
    }
}



export default App;